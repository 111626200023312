
// @ts-nocheck


export const localeCodes =  [
  "en-us",
  "de-de",
  "sv-se",
  "es-es",
  "fr-fr",
  "it-it",
  "is"
]

export const localeLoaders = {
  "en-us": [{ key: "../locales/en.ts", load: () => import("../locales/en.ts" /* webpackChunkName: "locale__vercel_path0_locales_en_ts" */), cache: true }],
  "de-de": [{ key: "../locales/de.ts", load: () => import("../locales/de.ts" /* webpackChunkName: "locale__vercel_path0_locales_de_ts" */), cache: true }],
  "sv-se": [{ key: "../locales/se.ts", load: () => import("../locales/se.ts" /* webpackChunkName: "locale__vercel_path0_locales_se_ts" */), cache: true }],
  "es-es": [{ key: "../locales/es.ts", load: () => import("../locales/es.ts" /* webpackChunkName: "locale__vercel_path0_locales_es_ts" */), cache: true }],
  "fr-fr": [{ key: "../locales/fr.ts", load: () => import("../locales/fr.ts" /* webpackChunkName: "locale__vercel_path0_locales_fr_ts" */), cache: true }],
  "it-it": [{ key: "../locales/it.ts", load: () => import("../locales/it.ts" /* webpackChunkName: "locale__vercel_path0_locales_it_ts" */), cache: true }],
  "is": [{ key: "../locales/is.ts", load: () => import("../locales/is.ts" /* webpackChunkName: "locale__vercel_path0_locales_is_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "name": "English",
      "code": "en-us",
      "language": "en",
      "files": [
        "/vercel/path0/locales/en.ts"
      ]
    },
    {
      "name": "Deutsch",
      "code": "de-de",
      "language": "de",
      "files": [
        "/vercel/path0/locales/de.ts"
      ]
    },
    {
      "name": "Svenska",
      "code": "sv-se",
      "language": "sv",
      "files": [
        "/vercel/path0/locales/se.ts"
      ]
    },
    {
      "name": "Español",
      "code": "es-es",
      "language": "es",
      "files": [
        "/vercel/path0/locales/es.ts"
      ]
    },
    {
      "name": "Français",
      "code": "fr-fr",
      "language": "fr",
      "files": [
        "/vercel/path0/locales/fr.ts"
      ]
    },
    {
      "name": "Italiano",
      "code": "it-it",
      "language": "it",
      "files": [
        "/vercel/path0/locales/it.ts"
      ]
    },
    {
      "name": "Íslenska",
      "code": "is",
      "language": "is",
      "files": [
        "/vercel/path0/locales/is.ts"
      ]
    }
  ],
  "defaultLocale": "en-us",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.payanalytics.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "en-us": "/",
      "de-de": "/",
      "is": "/",
      "sv-se": "/",
      "es-es": "/",
      "fr-fr": "/",
      "it-it": "/"
    },
    "[slug]/index": {
      "en-us": "/[slug]",
      "de-de": "/[slug]",
      "is": "/[slug]",
      "sv-se": "/[slug]",
      "es-es": "/[slug]",
      "fr-fr": "/[slug]",
      "it-it": "/[slug]"
    },
    "contact-us/index": {
      "en-us": "/contact-us",
      "de-de": "/kontakt",
      "is": "/hafa-samband",
      "sv-se": "/kontakta-oss",
      "es-es": "/contacta-con-nosotros",
      "fr-fr": "/contactez-nous",
      "it-it": "/contattaci"
    },
    "book-a-demo/index": {
      "en-us": "/book-a-demo",
      "de-de": "/vorfuehrung-buchen",
      "is": "/boka-fund",
      "sv-se": "/boka-en-demonstration",
      "es-es": "/reserva-una-demo",
      "fr-fr": "/reservez-une-demo",
      "it-it": "/prenota-una-demo"
    },
    "partnerships/index": {
      "en-us": "/partnerships",
      "de-de": "/partnerschaften",
      "is": "/samstarf",
      "sv-se": "/partnerskap",
      "es-es": "/asociaciones",
      "fr-fr": "/partenariats",
      "it-it": "/partenariati"
    },
    "link-in-bio/index": {
      "en-us": "/link-in-bio",
      "de-de": "/link-in-bio",
      "is": "/link-in-bio",
      "sv-se": "/link-in-bio",
      "es-es": "/link-in-bio",
      "fr-fr": "/link-in-bio",
      "it-it": "/link-in-bio"
    },
    "faq/index": {
      "en-us": "/faq",
      "de-de": "/fragen-und-antworten",
      "is": "/spurningar-og-svor",
      "sv-se": "/vanliga-fragor",
      "es-es": "/preguntas-frecuentes",
      "fr-fr": "/foire-aux-questions",
      "it-it": "/domande-frequenti"
    },
    "cookie-policy/index": {
      "en-us": "/cookie-policy",
      "de-de": "/cookie-richtlinie",
      "is": "/vafrakokustefna",
      "sv-se": "/cookiepolicy",
      "es-es": "/politica-de-cookies",
      "fr-fr": "/politique-cookies",
      "it-it": "/informativa-cookie"
    },
    "newsroom/index": {
      "en-us": "/newsroom",
      "de-de": "/nachrichten",
      "is": "/frettir",
      "sv-se": "/nyhetsrummet",
      "es-es": "/sala-de-prensa",
      "fr-fr": "/espace-presse",
      "it-it": "/area-stampa"
    },
    "newsroom/[slug]/index": {
      "en-us": "/newsroom/[slug]",
      "de-de": "/nachrichten/[slug]",
      "is": "/frettir/[slug]",
      "sv-se": "/nyhetsrummet/[slug]",
      "es-es": "/sala-de-prensa/[slug]",
      "fr-fr": "/espace-presse/[slug]",
      "it-it": "/area-stampa/[slug]"
    },
    "successfully-booked-a-demo/index": {
      "en-us": "/successfully-booked-a-demo",
      "de-de": "/successfully-booked-a-demo",
      "is": "/successfully-booked-a-demo",
      "sv-se": "/successfully-booked-a-demo",
      "es-es": "/successfully-booked-a-demo",
      "fr-fr": "/successfully-booked-a-demo",
      "it-it": "/successfully-booked-a-demo"
    },
    "privacy-policy/index": {
      "en-us": "/privacy-policy",
      "de-de": "/datenschutzrichtlinie",
      "is": "/personuverndarskilmalar",
      "sv-se": "/sekretesspolicy",
      "es-es": "/politica-de-privacidad",
      "fr-fr": "/politique-de-confidentialite",
      "it-it": "/informativa-privacy"
    },
    "product/index": {
      "en-us": "/product",
      "de-de": "/produkt",
      "is": "/hugbunadur",
      "sv-se": "/produkten",
      "es-es": "/producto",
      "fr-fr": "/produit",
      "it-it": "/prodotto"
    },
    "product/compensation-assistant/index": {
      "en-us": "/product/compensation-assistant",
      "de-de": "/produkt/verguetungsassistent",
      "is": "/hugbunadur/launaradgjafi",
      "sv-se": "/produkten/loeneassistent",
      "es-es": "/producto/asistente-de-remuneracion",
      "fr-fr": "/produit/assistant-de-remuneration",
      "it-it": "/prodotto/assistente-retribuzioni"
    },
    "product/job-evaluation/index": {
      "en-us": "/product/job-evaluation",
      "de-de": "/produkt/stellenbewertung",
      "is": "/hugbunadur/starfsmat",
      "sv-se": "/produkten/arbetsvaerdering",
      "es-es": "/producto/valoracion-de-los-puestos-de-trabajo",
      "fr-fr": "/produit/evaluation-des-emplois",
      "it-it": "/prodotto/valutazione-del-lavoro"
    },
    "product/reports/index": {
      "en-us": "/product/reports",
      "de-de": "/produkt/berichte",
      "is": "/hugbunadur/skyrslur",
      "sv-se": "/produkten/rapporter",
      "es-es": "/producto/informes",
      "fr-fr": "/produit/rapports",
      "it-it": "/prodotto/report"
    },
    "product/user-access-controls/index": {
      "en-us": "/product/user-access-controls",
      "de-de": "/produkt/benutzerzugriffskontrollen",
      "is": "/hugbunadur/adgangsstyringar",
      "sv-se": "/produkten/styrning-av-anvaendaratkomst",
      "es-es": "/producto/control-de-acceso-de-los-usuarios",
      "fr-fr": "/produit/controles-dacces-des-utilisateurs",
      "it-it": "/prodotto/controlli-degli-accessi-utente"
    },
    "product/workforce-analytics/index": {
      "en-us": "/product/workforce-analytics",
      "de-de": "/produkt/personalanalyse",
      "is": "/hugbunadur/jafnrettisvisar",
      "sv-se": "/produkten/personalanalys",
      "es-es": "/producto/analisis-del-personal",
      "fr-fr": "/produit/analyse-du-personnel",
      "it-it": "/prodotto/analisi-del-personale"
    },
    "product/software-security/index": {
      "en-us": "/product/software-security",
      "de-de": "/produkt/software-sicherheit",
      "is": "/hugbunadur/oeryggi-hugbunadarins",
      "sv-se": "/produkten/programvarusaekerhet",
      "es-es": "/producto/seguridad-del-software",
      "fr-fr": "/produit/securite-du-logiciel",
      "it-it": "/prodotto/sicurezza-del-software"
    },
    "product/data-management/index": {
      "en-us": "/product/data-management",
      "de-de": "/produkt/datenmanagement",
      "is": "/hugbunadur/gagnastjornun",
      "sv-se": "/produkten/hantering-av-data",
      "es-es": "/producto/gestion-de-los-datos",
      "fr-fr": "/produit/gestion-des-donnees",
      "it-it": "/prodotto/gestione-dei-dati"
    },
    "product/pay-equity-analysis/index": {
      "en-us": "/product/pay-equity-analysis",
      "de-de": "/produkt/analyse-der-lohngleichheit",
      "is": "/hugbunadur/launagreiningar",
      "sv-se": "/produkten/raettvisa-loener-analys",
      "es-es": "/producto/analisis-de-igualdad-salarial",
      "fr-fr": "/produit/analyse-de-l-equite-salariale",
      "it-it": "/prodotto/analisi-dell-equita-retributiva"
    },
    "product/value-based-comparison/index": {
      "en-us": "/product/value-based-comparison",
      "de-de": "/produkt/wertbasierter-vergleich",
      "is": "/hugbunadur/virdismidadur-samanburdur",
      "sv-se": "/produkten/loenekartlaeggning",
      "es-es": "/producto/comparacion-basada-en-el-valor",
      "fr-fr": "/produit/comparaison-selon-la-valeur",
      "it-it": "/prodotto/confronto-basato-sul-valore"
    },
    "product/remediation-actions/index": {
      "en-us": "/product/remediation-actions",
      "de-de": "/produkt/abhilfemassnahmen",
      "is": "/hugbunadur/haekkunartilloegur",
      "sv-se": "/produkten/avhjaelpande-atgaerder",
      "es-es": "/producto/medidas-de-solucion",
      "fr-fr": "/produit/recommandations-correctives",
      "it-it": "/prodotto/azioni-di-riparazione"
    },
    "coffee-talk/index": {
      "en-us": "/coffee-talk",
      "de-de": "/coffee-talk",
      "is": "/coffee-talk",
      "sv-se": "/coffee-talk",
      "es-es": "/coffee-talk",
      "fr-fr": "/coffee-talk",
      "it-it": "/coffee-talk"
    },
    "coffee-talk/[slug]/index": {
      "en-us": "/coffee-talk/[slug]",
      "de-de": "/coffee-talk/[slug]",
      "is": "/coffee-talk/[slug]",
      "sv-se": "/coffee-talk/[slug]",
      "es-es": "/coffee-talk/[slug]",
      "fr-fr": "/coffee-talk/[slug]",
      "it-it": "/coffee-talk/[slug]"
    },
    "resources/index": {
      "en-us": "/resources",
      "de-de": "/ressourcen",
      "is": "/fraedsluefni",
      "sv-se": "/resurser",
      "es-es": "/recursos",
      "fr-fr": "/ressources",
      "it-it": "/risorse"
    },
    "resources/local-requirements/index": {
      "en-us": "/resources/local-requirements",
      "de-de": "/ressourcen/lokale-anforderungen",
      "is": "/fraedsluefni/stadbundnar-krofur",
      "sv-se": "/resurser/lokala-krav",
      "es-es": "/recursos/requisitos-locales",
      "fr-fr": "/ressources/exigences-locales",
      "it-it": "/risorse/requisiti-locali"
    },
    "resources/local-requirements/[slug]/index": {
      "en-us": "/resources/local-requirements/[slug]",
      "de-de": "/ressourcen/lokale-anforderungen/[slug]",
      "is": "/fraedsluefni/stadbundnar-krofur/[slug]",
      "sv-se": "/resurser/lokala-krav/[slug]",
      "es-es": "/recursos/requisitos-locales/[slug]",
      "fr-fr": "/ressources/exigences-locales/[slug]",
      "it-it": "/risorse/requisiti-locali/[slug]"
    },
    "resources/guides/index": {
      "en-us": "/resources/guides",
      "de-de": "/ressourcen/guides",
      "is": "/fraedsluefni/leidarvisar",
      "sv-se": "/resurser/guider",
      "es-es": "/recursos/guias",
      "fr-fr": "/ressources/guides",
      "it-it": "/risorse/guide"
    },
    "resources/guides/[slug]/index": {
      "en-us": "/resources/guides/[slug]",
      "de-de": "/ressourcen/guides/[slug]",
      "is": "/fraedsluefni/leidarvisar/[slug]",
      "sv-se": "/resurser/guider/[slug]",
      "es-es": "/recursos/guias/[slug]",
      "fr-fr": "/ressources/guides/[slug]",
      "it-it": "/risorse/guide/[slug]"
    },
    "resources/customer-success/index": {
      "en-us": "/resources/customer-success",
      "de-de": "/ressourcen/kundenerfolg",
      "is": "/fraedsluefni/arangur-vidskiptavina",
      "sv-se": "/resurser/kundframgang",
      "es-es": "/recursos/exito-del-cliente",
      "fr-fr": "/ressources/succes-client",
      "it-it": "/risorse/successo-clienti"
    },
    "resources/customer-success/[slug]/index": {
      "en-us": "/resources/customer-success/[slug]",
      "de-de": "/ressourcen/kundenerfolg/[slug]",
      "is": "/fraedsluefni/arangur-vidskiptavina/[slug]",
      "sv-se": "/resurser/kundframgang/[slug]",
      "es-es": "/recursos/exito-del-cliente/[slug]",
      "fr-fr": "/ressources/succes-client/[slug]",
      "it-it": "/risorse/successo-clienti/[slug]"
    },
    "resources/articles/index": {
      "en-us": "/resources/articles",
      "de-de": "/ressourcen/artikel",
      "is": "/fraedsluefni/greinar",
      "sv-se": "/resurser/artiklar",
      "es-es": "/recursos/articulos",
      "fr-fr": "/ressources/articles",
      "it-it": "/risorse/articoli"
    },
    "resources/articles/[slug]/index": {
      "en-us": "/resources/articles/[slug]",
      "de-de": "/ressourcen/artikel/[slug]",
      "is": "/fraedsluefni/greinar/[slug]",
      "sv-se": "/resurser/artiklar/[slug]",
      "es-es": "/recursos/articulos/[slug]",
      "fr-fr": "/ressources/articles/[slug]",
      "it-it": "/risorse/articoli/[slug]"
    },
    "company/index": {
      "en-us": "/company",
      "de-de": "/unternehmen",
      "is": "/fyrirtaekid",
      "sv-se": "/foretag",
      "es-es": "/empresa",
      "fr-fr": "/entreprise",
      "it-it": "/azienda"
    },
    "careers/index": {
      "en-us": "/careers",
      "de-de": "/jobs",
      "is": "/storf",
      "sv-se": "/karriar",
      "es-es": "/carreras-profesionales",
      "fr-fr": "/offres-emploi",
      "it-it": "/carriere"
    },
    "careers/[slug]/index": {
      "en-us": "/careers/[slug]",
      "de-de": "/jobs/[slug]",
      "is": "/storf/[slug]",
      "sv-se": "/karriar/[slug]",
      "es-es": "/carreras-profesionales/[slug]",
      "fr-fr": "/offres-emploi/[slug]",
      "it-it": "/carriere/[slug]"
    },
    "trust-center/index": {
      "en-us": "/trust-center",
      "de-de": "/trust-center",
      "is": "/trust-center",
      "sv-se": "/trust-center",
      "es-es": "/trust-center",
      "fr-fr": "/trust-center",
      "it-it": "/trust-center"
    },
    "trust-center/[slug]/index": {
      "en-us": "/trust-center/[slug]",
      "de-de": "/trust-center/[slug]",
      "is": "/trust-center/[slug]",
      "sv-se": "/trust-center/[slug]",
      "es-es": "/trust-center/[slug]",
      "fr-fr": "/trust-center/[slug]",
      "it-it": "/trust-center/[slug]"
    },
    "terms-and-conditions/index": {
      "en-us": "/terms-and-conditions",
      "de-de": "/agb",
      "is": "/skilmalar",
      "sv-se": "/villkor",
      "es-es": "/terminos-y-condiciones",
      "fr-fr": "/termes-et-conditions",
      "it-it": "/termini-e-condizioni"
    },
    "newsletter/index": {
      "en-us": "/newsletter",
      "de-de": "/email-verteiler",
      "is": "/frettabref",
      "sv-se": "/sandlista",
      "es-es": "/boletin-informativo",
      "fr-fr": "/bulletin",
      "it-it": "/newsletter"
    },
    "brand/index": {
      "en-us": "/brand",
      "de-de": "/marken-assets",
      "is": "/vorumerki",
      "sv-se": "/varumarkestillgangar",
      "es-es": "/marca",
      "fr-fr": "/marque",
      "it-it": "/marchio"
    },
    "seo/index": {
      "en-us": "/seo",
      "de-de": "/seo",
      "is": "/seo",
      "sv-se": "/seo",
      "es-es": "/seo",
      "fr-fr": "/seo",
      "it-it": "/seo"
    },
    "seo/pages-structure/index": {
      "en-us": "/seo/pages-structure",
      "de-de": "/seo/pages-structure",
      "is": "/seo/pages-structure",
      "sv-se": "/seo/pages-structure",
      "es-es": "/seo/pages-structure",
      "fr-fr": "/seo/pages-structure",
      "it-it": "/seo/pages-structure"
    },
    "seo/all-articles/index": {
      "en-us": "/seo/all-articles",
      "de-de": "/seo/all-articles",
      "is": "/seo/all-articles",
      "sv-se": "/seo/all-articles",
      "es-es": "/seo/all-articles",
      "fr-fr": "/seo/all-articles",
      "it-it": "/seo/all-articles"
    },
    "seo/metadata/index": {
      "en-us": "/seo/metadata",
      "de-de": "/seo/metadata",
      "is": "/seo/metadata",
      "sv-se": "/seo/metadata",
      "es-es": "/seo/metadata",
      "fr-fr": "/seo/metadata",
      "it-it": "/seo/metadata"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "English",
    "code": "en-us",
    "language": "en",
    "files": [
      {
        "path": "/vercel/path0/locales/en.ts"
      }
    ]
  },
  {
    "name": "Deutsch",
    "code": "de-de",
    "language": "de",
    "files": [
      {
        "path": "/vercel/path0/locales/de.ts"
      }
    ]
  },
  {
    "name": "Svenska",
    "code": "sv-se",
    "language": "sv",
    "files": [
      {
        "path": "/vercel/path0/locales/se.ts"
      }
    ]
  },
  {
    "name": "Español",
    "code": "es-es",
    "language": "es",
    "files": [
      {
        "path": "/vercel/path0/locales/es.ts"
      }
    ]
  },
  {
    "name": "Français",
    "code": "fr-fr",
    "language": "fr",
    "files": [
      {
        "path": "/vercel/path0/locales/fr.ts"
      }
    ]
  },
  {
    "name": "Italiano",
    "code": "it-it",
    "language": "it",
    "files": [
      {
        "path": "/vercel/path0/locales/it.ts"
      }
    ]
  },
  {
    "name": "Íslenska",
    "code": "is",
    "language": "is",
    "files": [
      {
        "path": "/vercel/path0/locales/is.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
