import consts from '@/consts'

export default (input: string) => {
  let linkUrl = input

  consts.redirects.forEach(([oldPath, newPath]) => {
    linkUrl = linkUrl.replaceAll(oldPath, newPath)
  })

  return linkUrl
}
