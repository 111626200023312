type crossoriginOptions = '' | 'anonymous' | 'use-credentials'
type LinkAsType =
  | 'object'
  | 'font'
  | 'style'
  | 'script'
  | 'worker'
  | 'audio'
  | 'document'
  | 'embed'
  | 'fetch'
  | 'image'
  | 'track'
  | 'video'

const consts = {
  baseUrl: 'https://www.payanalytics.com',
  websiteUrl: 'https://www.payanalytics.com/',
  bookDemoSuccessUrl: 'https://www.payanalytics.com/successfully-booked-a-demo',
  baseDomain: 'payanalytics.com',
  queryParamsThatShouldBePartOfCanonicalUrl: ['page'],
  companyName: 'PayAnalytics by beqom',
  prismicRepoName: 'payanalyticscom',
  defaultMetaImageUrl: 'https://www.payanalytics.com/1200x630-payanalytics.gif',
  defaultLogoMark: 'https://www.payanalytics.com/payanalytics-logomark.png',
  reCaptchaSiteKey: '6LdGIOIUAAAAANGim6puCV9qkwK-wkCVrWUbiPWC',
  articleListId: 'ARTICLE-LIST',
  articleNotFoundUrlPart: 'article-not-translated',
  tocAnchorLinkPrefix: '_',
  fathomTrackingCodeForDotCom: 'AMRMDFOX',
  phoneSalesNorthAmerica: '+1-646-952-0088',
  phoneSalesGermany: '+49-89-8908-1697',
  phoneSalesFrance: '+33-1-89-54-97-77',
  phoneSalesUnitedKingdom: '+44-20-3668-6837',
  phoneSalesItaly: '+39-02-62-03-2124',
  phoneSalesWorldwide: '+41-58-255-08-00',
  phoneSupportNorthAmerica: '+1-800-986-5681',
  phoneSupportWorldwide: '+41-44-551-43-75',
  contactUsPage: 'https://www.payanalytics.com/contact-us',
  meta: {
    creator: 'PayAnalytics by beqom',
    default: [
      { property: 'og:type', content: 'website' },
      { name: 'twitter:site', content: '@payanalytics' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site:id', content: '@payanalytics' },
      { name: 'og:image:height', content: '630' },
      { name: 'og:image:width', content: '1200' },
      { name: 'facebook-domain-verification', content: 'sdtuy3opys1m9h2zuj2pbr0gbglk6v' },
    ],
  },
  favicons: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicons/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/favicons/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      color: '#5bbad5',
      href: '/favicons/safari-pinned-tab.svg',
    },
    {
      rel: 'shortcut icon',
      href: '/favicons/favicon.ico',
    },
  ],
  preconnects: [
    // Preload fonts.
    {
      rel: 'preload',
      href: '/fonts/poppins/Poppins-SemiBold.ttf',
      as: 'font' as LinkAsType,
      type: 'font/ttf',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
    {
      rel: 'preload',
      href: '/fonts/poppins/Poppins-Bold.ttf',
      as: 'font' as LinkAsType,
      type: 'font/ttf',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
    {
      rel: 'preload',
      href: '/fonts/poppins/Poppins-Regular.ttf',
      as: 'font' as LinkAsType,
      type: 'font/ttf',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
    {
      rel: 'preload',
      href: '/fonts/open_sans/open_sans_variable.ttf',
      as: 'font' as LinkAsType,
      type: 'font/ttf',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
    {
      rel: 'preload',
      href: '/fonts/open_sans/open_sans_italic_variable.ttf',
      as: 'font' as LinkAsType,
      type: 'font/ttf',
      crossorigin: 'anonymous' as crossoriginOptions,
    },
    // Google recaptcha.
    {
      rel: 'preconnect',
      href: 'https://www.google.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // Google Tag Manager.
    {
      rel: 'preconnect',
      href: 'https://www.googletagmanager.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // Google recaptcha.
    {
      rel: 'preconnect',
      href: 'https://www.gstatic.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // Fathom Analytics.
    {
      rel: 'preconnect',
      href: 'https://cdn.usefathom.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // Google Analytics.
    {
      rel: 'preconnect',
      href: 'https://www.google-analytics.com/',
      crossorigin: '' as crossoriginOptions,
    },
    // Facebook
    {
      rel: 'preconnect',
      href: 'https://connect.facebook.net/',
      crossorigin: '' as crossoriginOptions,
    },
  ],
  socialUrls: {
    twitter: {
      url: 'https://twitter.com/payanalytics',
      ariaName: 'Twitter',
      svgIconName: 'TwitterSvg',
    },
    facebook: {
      url: 'https://www.facebook.com/PayAnalytics/',
      ariaName: 'Facebook',
      svgIconName: 'FacebookSvg',
    },
    instagram: {
      url: 'https://www.instagram.com/payanalytics/',
      ariaName: 'Instagram',
      svgIconName: 'InstagramSvg',
    },
    linkedIn: {
      url: 'https://www.linkedin.com/company/payanalytics',
      ariaName: 'LinkedIn',
      svgIconName: 'LinkedInSvg',
    },
    youTube: {
      url: 'https://www.youtube.com/channel/UCgDP02X0ne4kCtDFWM66qXw',
      ariaName: 'YouTube',
      svgIconName: 'YouTubeSvg',
    },
    vimeo: {
      url: 'https://vimeo.com/payanalytics',
      ariaName: 'Vimeo',
      svgIconName: 'VimeoSvg',
    },
    rssNewsfeed: {
      url: '/rss/newsfeed',
      ariaName: 'RSS',
      svgIconName: 'RssSvg',
    },
    sitemap: {
      url: '/sitemap.xml',
      ariaName: 'Sitemap',
      svgIconName: 'SitemapSvg',
    },
  } as SocialUrlObj,
  rssXmlSettings: { compact: true, spaces: 4 },
  defaultArticleCategory: 'Article' as NewsroomArticleCategory,
  articleCategoriesWithoutDate: ['trust_center'] as ValidArticleCategory[],
  articleCategoriesWithoutTags: ['trust_center'] as ValidArticleCategory[],
  newsroomPage: {
    pageSize: 12,
    i18nKey: 'newsroom/index' as WebsitePageI18nKey,
    rssPath: '/:lang/rss/newsfeed',
    articleNotFoundSlug: 'article-not-found',
    newsfeedArticleCategories: [
      'Interview',
      'press_release',
      'Video',
      'event',
      'news',
    ] as NewsroomArticleCategory[],
  },
  careersPage: {
    i18nKey: 'careers/index' as WebsitePageI18nKey,
    prismicLang: 'en-us' as PrismicLocaleCode,
    prismicArticleCategory: 'job_open_position' as CareersPageArticleCategory,
  },
  trustCenterPage: {
    i18nKey: 'trust-center/index' as WebsitePageI18nKey,
    prismicLang: 'en-us' as PrismicLocaleCode,
    prismicArticleCategory: ['trust_center'] as TrustCenterArticleCategory[],
  },
  guidesPage: {
    prismicArticleCategories: ['ebook', 'guide'] as GuidesPageArticleCategory[],
    pageSize: 12,
    i18nKey: 'resources/guides/index' as WebsitePageI18nKey,
  },
  customerSuccessPage: {
    prismicArticleCategories: ['customer_success_story'] as CustomerSuccessPageArticleCategory[],
    pageSize: 12,
    i18nKey: 'resources/customer-success/index' as WebsitePageI18nKey,
  },
  articlesPage: {
    prismicArticleCategories: ['Article'] as ArticlesPageArticleCategory[],
    pageSize: 12,
    i18nKey: 'resources/articles/index' as WebsitePageI18nKey,
  },
  linkInBioPage: {
    prismicLang: 'en-us' as PrismicLocaleCode,
  },
  coffeeTalkPodcast: {
    overviewPageUrl: 'https://www.payanalytics.com/coffee-talk',
    rssFeedUrl: 'https://www.payanalytics.com/rss/coffee-talk-podcast',
    copyright: 'Margret Bjarnadottir and Henrike von Platen',
    author: 'Margret Bjarnadottir and Henrike von Platen',
    managingEditor: 'Margret Bjarnadottir',
    keywords: 'HR, Pay Equity, Compensation, Human Resources, Business',
    email: 'accounts@payanalytics.com',
    prismicLang: 'en-us' as PrismicLocaleCode,
    pageSize: 12,
    i18nKey: 'coffee-talk/index' as WebsitePageI18nKey,
    prismicArticleCategory: 'coffee_talk' as CoffeeTalkPageArticleCategory,
    rssPath: '/rss/coffee-talk-podcast',
  },
  localRequirementPage: {
    pageSize: 99,
    i18nKey: 'resources/local-requirements/index',
    prismicArticleCategory: 'local_requirement' as LocalRequirementsPageArticleCategory,
  },
  singleArticleFetchLinksArray: [
    'article_author.name',
    'article_author.description',
    'article_author.image',
    'article_author.email_address',
    'article_author.linkedin_url',
    'article_author.twitter_url',
    'article_author.book_a_meeting_url',
    'legal_clause.text',
  ],
  permanentlyDeletedPages: ['/worldatwork-total-rewards-23', '/careers/software-engineer-python'],
  redirects: [
    ['/about-payanalytics', '/company'],
    [
      '/newsroom/dei-workplace-equity-initiatives-how-to',
      '/resources/articles/dei-workplace-equity-initiatives-how-to',
    ],
    [
      '/newsroom/workplace-equity-dei-initiatives-pitfalls-help-guide',
      '/resources/articles/workplace-equity-dei-initiatives-pitfalls-help-guide',
    ],
    [
      '/newsroom/pay-philosophy-coffee-talk-2023-year-in-review',
      '/resources/articles/pay-philosophy-coffee-talk-2023-year-in-review',
    ],
    [
      '/newsroom/brazil-equal-pay-law-requirements-reporting',
      '/resources/articles/brazil-equal-pay-law-requirements-reporting',
    ],
    [
      '/newsroom/pay-analytics-year-review-2023-acquisition-awards-partnerships',
      '/resources/articles/pay-analytics-year-review-2023-acquisition-awards-partnerships',
    ],
    [
      '/newsroom/hawaii-pay-transparency-law-2023',
      '/resources/articles/hawaii-pay-transparency-law-2023',
    ],
    ['/newsroom/pay-equity-laws-canada', '/resources/articles/pay-equity-laws-canada'],
    [
      '/newsroom/how-to-calculate-pay-gap-pt2-demographic-pay-gap-root-causes',
      '/resources/articles/how-to-calculate-pay-gap-pt2-demographic-pay-gap-root-causes',
    ],
    [
      '/newsroom/pay-equity-laws-iceland-equal-pay-certification',
      '/resources/articles/pay-equity-laws-iceland-equal-pay-certification',
    ],
    [
      '/newsroom/how-to-calculate-pay-gap-mean-average-median',
      '/resources/articles/how-to-calculate-pay-gap-mean-average-median',
    ],
    [
      '/newsroom/compensation-committee-board-directors-pay-equity-plan-presentation',
      '/resources/guides/compensation-committee-board-directors-pay-equity-plan-presentation',
    ],
    [
      '/newsroom/eu-pay-transparency-directive-rules',
      '/resources/guides/eu-pay-transparency-directive-rules',
    ],
    [
      '/newsroom/the-unadjusted-pay-gap-vs-the-adjusted-pay-gap',
      '/resources/articles/the-unadjusted-pay-gap-vs-the-adjusted-pay-gap',
    ],
    [
      '/newsroom/eba-guidelines-renumeration-gender-pay-gap-how-to-comply',
      '/resources/articles/eba-guidelines-renumeration-gender-pay-gap-how-to-comply',
    ],
    [
      '/newsroom/tenure-hiring-pay-compression-best-practices',
      '/resources/articles/tenure-hiring-pay-compression-best-practices',
    ],
    [
      '/newsroom/jersey-city-pay-transparency-law',
      '/resources/articles/jersey-city-pay-transparency-law',
    ],
    ['/newsroom/nevada-pay-transparency-law', '/resources/articles/nevada-pay-transparency-law'],
    [
      '/newsroom/new-york-pay-transparency-law',
      '/resources/articles/new-york-pay-transparency-law',
    ],
    [
      '/newsroom/data-first-pay-equity-analysis',
      '/resources/articles/data-first-pay-equity-analysis',
    ],
    [
      '/newsroom/csrd-eu-corporate-sustainability-reporting-directive-pay-equity',
      '/resources/articles/csrd-eu-corporate-sustainability-reporting-directive-pay-equity',
    ],
    [
      '/newsroom/pay-transparency-benefits-wage-gap',
      '/resources/articles/pay-transparency-benefits-wage-gap',
    ],
    [
      '/newsroom/guide-gender-equality-certification-italy',
      '/resources/guides/guide-gender-equality-certification-italy',
    ],
    [
      '/newsroom/emerging-trends-ai-tools-hr-total-rewards',
      '/resources/articles/emerging-trends-ai-tools-hr-total-rewards',
    ],
    [
      '/newsroom/our-top-5-total-rewards-23-sessions',
      '/resources/articles/our-top-5-total-rewards-23-sessions',
    ],
    [
      '/newsroom/people-analytics-tools-fairness-total-rewards23',
      '/resources/articles/people-analytics-tools-fairness-total-rewards23',
    ],
    [
      '/newsroom/australia-pay-gap-reporting-wgea-update',
      '/resources/articles/australia-pay-gap-reporting-wgea-update',
    ],
    ['/newsroom/pay-equity-ultimate-guide', '/resources/guides/pay-equity-ultimate-guide'],
    [
      '/newsroom/maryland-pay-transparency-law',
      '/resources/articles/maryland-pay-transparency-law',
    ],
    [
      '/newsroom/payanalytics-support-eu-pay-transparency-directive',
      '/resources/articles/payanalytics-support-eu-pay-transparency-directive',
    ],
    [
      '/newsroom/connecticut-pay-transparency-law',
      '/resources/articles/connecticut-pay-transparency-law',
    ],
    [
      '/newsroom/pay-equity-analysis-for-small-populations',
      '/resources/articles/pay-equity-analysis-for-small-populations',
    ],
    [
      '/newsroom/colorado-pay-transparency-law',
      '/resources/articles/colorado-pay-transparency-law',
    ],
    [
      '/newsroom/us-pay-transparency-laws-by-state',
      '/resources/articles/us-pay-transparency-laws-by-state',
    ],
    ['/newsroom/pay-equity-laws-portugal', '/resources/articles/pay-equity-laws-portugal'],
    [
      '/newsroom/pay-gap-reporting-changes-japan',
      '/resources/articles/pay-gap-reporting-changes-japan',
    ],
    ['/newsroom/new-workplace-equity-feature', '/resources/articles/new-workplace-equity-feature'],
    [
      '/newsroom/new-pay-transparency-law-washington',
      '/resources/articles/new-pay-transparency-law-washington',
    ],
    [
      '/newsroom/ireland-rolls-out-new-pay-equity-law',
      '/resources/articles/ireland-rolls-out-new-pay-equity-law',
    ],
    [
      '/newsroom/new-pay-transparency-legislation-in-california',
      '/resources/articles/new-pay-transparency-legislation-in-california',
    ],
    [
      '/newsroom/payanalytics-year-in-review-2022',
      '/resources/articles/payanalytics-year-in-review-2022',
    ],
    [
      '/newsroom/pay-transparency-requirements-take-effect-in-new-york',
      '/resources/articles/pay-transparency-requirements-take-effect-in-new-york',
    ],
    [
      '/newsroom/getting-started-pay-equity-five-steps',
      '/resources/guides/getting-started-pay-equity-five-steps',
    ],
    [
      '/newsroom/using-people-analytics-tools-to-increase-pay-equity',
      '/resources/guides/using-people-analytics-tools-to-increase-pay-equity',
    ],
    [
      '/newsroom/payanalytics-reporting-for-canadas-pay-equity-act',
      '/resources/articles/payanalytics-reporting-for-canadas-pay-equity-act',
    ],
    [
      '/newsroom/allianzs-equal-pay-project-wins-the-german-hr-management',
      '/resources/customer-success/allianzs-equal-pay-project-wins-the-german-hr-management',
    ],
    [
      '/newsroom/highlights-from-the-workplace-equity-forum-2022',
      '/resources/articles/highlights-from-the-workplace-equity-forum-2022',
    ],
    [
      '/newsroom/customer-success-stories-ruv',
      '/resources/customer-success/customer-success-stories-ruv',
    ],
    [
      '/newsroom/using-technology-to-achieve-pay-equity',
      '/resources/articles/using-technology-to-achieve-pay-equity',
    ],
    [
      '/newsroom/margret-bjarnadottir-blog-post-for-trend-report',
      '/resources/articles/margret-bjarnadottir-blog-post-for-trend-report',
    ],
    [
      '/newsroom/margret-bjarnadottir-the-nordic-dei-paradox-not-so-paradoxical',
      '/resources/articles/margret-bjarnadottir-the-nordic-dei-paradox-not-so-paradoxical',
    ],
    [
      '/newsroom/california-strengthens-its-pay-equity-requirements',
      '/resources/articles/california-strengthens-its-pay-equity-requirements',
    ],
    [
      '/newsroom/customer-success-stories-vis',
      '/resources/customer-success/customer-success-stories-vis',
    ],
    [
      '/newsroom/what-happens-in-organizations-when-pay-becomes-more-transparent',
      '/resources/articles/what-happens-in-organizations-when-pay-becomes-more-transparent',
    ],
    [
      '/newsroom/what-italys-new-equal-pay-legislation-means-for-italian-companies',
      '/resources/articles/what-italys-new-equal-pay-legislation-means-for-italian-companies',
    ],
    [
      '/newsroom/about-the-canada-pay-equity-act',
      '/resources/articles/about-the-canada-pay-equity-act',
    ],
    [
      '/newsroom/how-to-use-use-people-analytics-to-build-an-equitable',
      '/resources/articles/how-to-use-use-people-analytics-to-build-an-equitable',
    ],
    ['/newsroom/spain-new-legislation', '/resources/articles/spain-new-legislation'],
    [
      '/newsroom/companies-in-spain-are-now-obligated-to-take-action',
      '/resources/articles/companies-in-spain-are-now-obligated-to-take-action',
    ],
    [
      '/newsroom/margret-bjarnadottir-blog-post-for-prca',
      '/resources/articles/margret-bjarnadottir-blog-post-for-prca',
    ],
    [
      '/newsroom/42-of-organizations-are-planning-for-pay-equity-adjustments-in-2020',
      '/resources/articles/42-of-organizations-are-planning-for-pay-equity-adjustments-in-2020',
    ],
    [
      '/newsroom/addressing-demographic-pay-gaps-with-data-driven-solutions-guest-feature-for-insidebigdata',
      '/resources/articles/addressing-demographic-pay-gaps-with-data-driven-solutions-guest-feature-for-insidebigdata',
    ],
    [
      '/newsroom/why-companies-attempts-to-close-the-gender-pay-gap-often-fail',
      '/resources/articles/why-companies-attempts-to-close-the-gender-pay-gap-often-fail',
    ],
    ['/de-de/ueber-payanalytics', '/de-de/unternehmen'],
    [
      '/de-de/nachrichten/the-unadjusted-pay-gap-vs-the-adjusted-pay-gap',
      '/de-de/ressourcen/artikel/the-unadjusted-pay-gap-vs-the-adjusted-pay-gap',
    ],
    [
      '/de-de/nachrichten/allianz-projekt-gewinnt-personalwirtschaftspreis',
      '/de-de/ressourcen/kundenerfolg/allianz-projekt-gewinnt-personalwirtschaftspreis',
    ],
    [
      '/de-de/nachrichten/margret-bjarnadottir-blogbeitrag-fur-trend-report',
      '/de-de/ressourcen/artikel/margret-bjarnadottir-blogbeitrag-fur-trend-report',
    ],
    ['/sv-se/om-payanalytics', '/sv-se/foretag'],
    ['/sv-se/resources/local-requirements', '/sv-se/resurser/lokala-krav'],
    [
      '/sv-se/nyhetsrummet/ojusterade-lonegapet-jamfort-med-det-justerade-lonegapet',
      '/sv-se/resurser/artiklar/ojusterade-lonegapet-jamfort-med-det-justerade-lonegapet',
    ],
    [
      '/sv-se/nyhetsrummet/allianz-projekt-vinner-det-tyska-priset-for-personalforvaltning',
      '/sv-se/resurser/kundframgang/allianz-projekt-vinner-det-tyska-priset-for-personalforvaltning',
    ],
    ['/es-es/sobre-payanalytics', '/es-es/empresa'],
    ['/es-es/brand', '/es-es/marca'],
    [
      '/es-es/sala-de-prensa/brecha-salarial-ajustada-vs-no-ajustada',
      '/es-es/recursos/articulos/brecha-salarial-ajustada-vs-no-ajustada',
    ],
    [
      '/es-es/sala-de-prensa/proyecto-igualdad-salarial-allianz-gana-el-premio-rh-gestion',
      '/es-es/recursos/exito-del-cliente/proyecto-igualdad-salarial-allianz-gana-el-premio-rh-gestion',
    ],
    [
      '/es-es/sala-de-prensa/nueva-legislacion-espanola',
      '/es-es/recursos/articulos/nueva-legislacion-espanola',
    ],
    ['/fr-fr/a-propos-de-payanalytics', '/fr-fr/entreprise'],
    ['/fr-fr/brand', '/fr-fr/marque'],
    [
      '/fr-fr/espace-presse/ecart-non-ajuste-vs-ecart-ajuste',
      '/fr-fr/ressources/articles/ecart-non-ajuste-vs-ecart-ajuste',
    ],
    [
      '/fr-fr/espace-presse/rapports-payanalytics-pour-la-loi-sur-equite-salariale',
      '/fr-fr/ressources/articles/rapports-payanalytics-pour-la-loi-sur-equite-salariale',
    ],
    [
      '/fr-fr/espace-presse/projet-equite-salariale-allianz-remporte-le-prix-rh-gestion',
      '/fr-fr/ressources/succes-client/projet-equite-salariale-allianz-remporte-le-prix-rh-gestion',
    ],
    [
      '/fr-fr/espace-presse/about-the-canada-pay-equity-act',
      '/fr-fr/ressources/articles/about-the-canada-pay-equity-act',
    ],
    ['/it-it/circa-payanalytics', '/it-it/azienda'],
    [
      '/it-it/area-stampa/guide-gender-equality-certification-italy',
      '/it-it/risorse/guide/guide-gender-equality-certification-italy',
    ],
    [
      '/it-it/area-stampa/the-unadjusted-pay-gap-vs-the-adjusted-pay-gap',
      '/it-it/risorse/articoli/the-unadjusted-pay-gap-vs-the-adjusted-pay-gap',
    ],
    [
      '/it-it/area-stampa/il-progetto-di-parita-retributiva-di-allianz-vince-il-premio-tedesco-per-la-gestione-hr',
      '/it-it/risorse/successo-clienti/il-progetto-di-parita-retributiva-di-allianz-vince-il-premio-tedesco-per-la-gestione-hr',
    ],
    [
      '/it-it/area-stampa/what-italys-new-equal-pay-legislation-means-for-italian-companies',
      '/it-it/risorse/articoli/what-italys-new-equal-pay-legislation-means-for-italian-companies',
    ],
    ['/is/um-payanalytics', '/is/fyrirtaekid'],
    [
      '/is/frettir/hvad-felst-i-jafnlaunavottun',
      '/is/fraedsluefni/greinar/hvad-felst-i-jafnlaunavottun',
    ],
    [
      '/is/frettir/hvað-felst-i-jafnlaunavottun',
      '/is/fraedsluefni/greinar/hvad-felst-i-jafnlaunavottun',
    ],
    [
      '/is/frettir/ny-tilskipun-esb-um-launagagnsaei',
      '/is/fraedsluefni/greinar/ny-tilskipun-esb-um-launagagnsaei',
    ],
    ['/is/frettir/hvad-er-jafnlaunavottun', '/is/fraedsluefni/greinar/hvad-er-jafnlaunavottun'],
    ['/product#job-evaluation', '/product/job-evaluation'],
    ['/product#compensation-mapping', '/product/value-based-comparison'],
    [
      '/is/frettir/ad-beita-gagnadrifnum-adferðum-til-að-loka-launabilum',
      '/is/fraedsluefni/greinar/ad-beita-gagnadrifnum-adferdum-til-ad-loka-launabilum',
    ],
    [
      '/is/greinar/ad-beita-gagnadrifnum-adfer%C3%B0um-til-a%C3%B0-loka-launabilum',
      '/is/fraedsluefni/greinar/ad-beita-gagnadrifnum-adferdum-til-ad-loka-launabilum',
    ],
    [
      '/is/greinar/ad-beita-gagnadrifnum-adfer%C3%B0um-til-a%C3%B0-loka-launabilum',
      '/is/fraedsluefni/greinar/ad-beita-gagnadrifnum-adferdum-til-ad-loka-launabilum',
    ],
    [
      '/is/frettir/landspitali-notar-hugbunad-payanalytics-til-a%C3%B0-vinna-gegn-launamun-kynjanna',
      '/is/frettir/landspitali-notar-hugbunad-payanalytics-til-ad-vinna-gegn-launamun-kynjanna',
    ],
    [
      '/is/frettir/landspitali-notar-hugbunad-payanalytics-til-að-vinna-gegn-launamun-kynjanna',
      '/is/frettir/landspitali-notar-hugbunad-payanalytics-til-ad-vinna-gegn-launamun-kynjanna',
    ],
    [
      '/is/frettir/samstarf-marka%C3%B0slauna-pwc-og-payanalytics',
      '/is/frettir/samstarf-markadslauna-pwc-og-payanalytics',
    ],
    [
      '/is/frettir/samstarf-markaðslauna-pwc-og-payanalytics',
      '/is/frettir/samstarf-markadslauna-pwc-og-payanalytics',
    ],
    [
      '/is/frettir/vi%C3%B0urkenningarathofn-vegna-al%C3%BEjo%C3%B0legrar-jafnlaunavottunar',
      '/is/frettir/vidurkenningarathofn-vegna-althjodlegrar-jafnlaunavottunar',
    ],
    [
      '/is/frettir/viðurkenningarathofn-vegna-alþjoðlegrar-jafnlaunavottunar',
      '/is/frettir/vidurkenningarathofn-vegna-althjodlegrar-jafnlaunavottunar',
    ],
    [
      '/is/frettir/margret-vilborg-i-vi%C3%B0tali-vid-morgunvaktina',
      '/is/frettir/margret-vilborg-i-vidtali-vid-morgunvaktina',
    ],
    [
      '/is/frettir/margret-vilborg-i-viðtali-vid-morgunvaktina',
      '/is/frettir/margret-vilborg-i-vidtali-vid-morgunvaktina',
    ],
    [
      '/is/frettir/islensk-fyrirt%C3%A6ki-i-lykilhlutverki-i-nyrri-al%C3%BEjo%C3%B0legri-jafnlaunavottun',
      '/is/frettir/islensk-fyrirtaeki-i-lykilhlutverki-i-nyrri-althjodlegri-jafnlaunavottun',
    ],
    [
      '/is/frettir/islensk-fyrirtæki-i-lykilhlutverki-i-nyrri-alþjoðlegri-jafnlaunavottun',
      '/is/frettir/islensk-fyrirtaeki-i-lykilhlutverki-i-nyrri-althjodlegri-jafnlaunavottun',
    ],
    [
      '/is/frettir/askoranir-og-t%C3%A6kif%C3%A6ri-i-jafnlaunamalum-9-nov-2022',
      '/is/frettir/askoranir-og-taekifaeri-i-jafnlaunamalum-9-nov-2022',
    ],
    [
      '/is/frettir/askoranir-og-tækifæri-i-jafnlaunamalum-9-nov-2022',
      '/is/frettir/askoranir-og-taekifaeri-i-jafnlaunamalum-9-nov-2022',
    ],
    [
      '/is/frettir/vi%C3%B0skiptavinur-vordur-tryggingafelag-payanalytics-hlytur-hvatningarver%C3%B0laun',
      '/is/frettir/vidskiptavinur-vordur-tryggingafelag-payanalytics-hlytur-hvatningarverdlaun',
    ],
    [
      '/is/frettir/viðskiptavinur-vordur-tryggingafelag-payanalytics-hlytur-hvatningarverðlaun',
      '/is/frettir/vidskiptavinur-vordur-tryggingafelag-payanalytics-hlytur-hvatningarverdlaun',
    ],
  ],
}

export default {
  ...consts,
  articleUrlPrefixMap: {
    Article: consts.articlesPage.i18nKey,
    Interview: consts.newsroomPage.i18nKey,
    Video: consts.newsroomPage.i18nKey,
    coffee_talk: consts.coffeeTalkPodcast.i18nKey,
    press_release: consts.newsroomPage.i18nKey,
    job_open_position: consts.careersPage.i18nKey,
    local_requirement: consts.localRequirementPage.i18nKey,
    event: consts.newsroomPage.i18nKey,
    customer_success_story: consts.customerSuccessPage.i18nKey,
    ebook: consts.guidesPage.i18nKey,
    guide: consts.guidesPage.i18nKey,
    news: consts.newsroomPage.i18nKey,
    trust_center: consts.trustCenterPage.i18nKey,
  } as ArticleUrlPrefixMap,
}
