import { default as indexlecgHUQVpLMeta } from "/vercel/path0/pages/[slug]/index.vue?macro=true";
import { default as index6cfhYSduZiMeta } from "/vercel/path0/pages/book-a-demo/index.vue?macro=true";
import { default as indexY6wAl4urbGMeta } from "/vercel/path0/pages/brand/index.vue?macro=true";
import { default as indexH4tP14oTKIMeta } from "/vercel/path0/pages/careers/[slug]/index.vue?macro=true";
import { default as indexoPfiXD35qmMeta } from "/vercel/path0/pages/careers/index.vue?macro=true";
import { default as indexwRJzc4AO8pMeta } from "/vercel/path0/pages/coffee-talk/[slug]/index.vue?macro=true";
import { default as indexnbeOhSm0EAMeta } from "/vercel/path0/pages/coffee-talk/index.vue?macro=true";
import { default as indexTHqTRxlhV2Meta } from "/vercel/path0/pages/company/index.vue?macro=true";
import { default as index1lVmB4IgMRMeta } from "/vercel/path0/pages/contact-us/index.vue?macro=true";
import { default as indexSacgtVxfWhMeta } from "/vercel/path0/pages/cookie-policy/index.vue?macro=true";
import { default as indexs8g6T0dsz8Meta } from "/vercel/path0/pages/faq/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index75sD3WTJ3JMeta } from "/vercel/path0/pages/link-in-bio/index.vue?macro=true";
import { default as indexkrT2YGg0DcMeta } from "/vercel/path0/pages/newsletter/index.vue?macro=true";
import { default as index7liRXQfhiZMeta } from "/vercel/path0/pages/newsroom/[slug]/index.vue?macro=true";
import { default as indexXoRKgcabZZMeta } from "/vercel/path0/pages/newsroom/index.vue?macro=true";
import { default as index6cI4i6dIJPMeta } from "/vercel/path0/pages/partnerships/index.vue?macro=true";
import { default as indexZqjRDHar7KMeta } from "/vercel/path0/pages/privacy-policy/index.vue?macro=true";
import { default as indexeI2VGgDzcTMeta } from "/vercel/path0/pages/product/compensation-assistant/index.vue?macro=true";
import { default as indexNk57TC42K5Meta } from "/vercel/path0/pages/product/data-management/index.vue?macro=true";
import { default as index7hfzYMFzRmMeta } from "/vercel/path0/pages/product/index.vue?macro=true";
import { default as index4TMAIMl93oMeta } from "/vercel/path0/pages/product/job-evaluation/index.vue?macro=true";
import { default as index12detMtsUHMeta } from "/vercel/path0/pages/product/pay-equity-analysis/index.vue?macro=true";
import { default as index0l0krOllGIMeta } from "/vercel/path0/pages/product/remediation-actions/index.vue?macro=true";
import { default as indexqKlsottMmMMeta } from "/vercel/path0/pages/product/reports/index.vue?macro=true";
import { default as indexk3SD12tiSeMeta } from "/vercel/path0/pages/product/software-security/index.vue?macro=true";
import { default as index6g8WYEF1qYMeta } from "/vercel/path0/pages/product/user-access-controls/index.vue?macro=true";
import { default as index0EFTy3VCoaMeta } from "/vercel/path0/pages/product/value-based-comparison/index.vue?macro=true";
import { default as indexs7CDNZrf2UMeta } from "/vercel/path0/pages/product/workforce-analytics/index.vue?macro=true";
import { default as indexYV4bFhYC7lMeta } from "/vercel/path0/pages/resources/articles/[slug]/index.vue?macro=true";
import { default as indexTJw3JcI2nuMeta } from "/vercel/path0/pages/resources/articles/index.vue?macro=true";
import { default as indexUKR4NJMqCXMeta } from "/vercel/path0/pages/resources/customer-success/[slug]/index.vue?macro=true";
import { default as indexS2NX9qtFOrMeta } from "/vercel/path0/pages/resources/customer-success/index.vue?macro=true";
import { default as indexFLQwqwIwF9Meta } from "/vercel/path0/pages/resources/guides/[slug]/index.vue?macro=true";
import { default as indexGdt1xUfWViMeta } from "/vercel/path0/pages/resources/guides/index.vue?macro=true";
import { default as indexS1Y0mOtI4KMeta } from "/vercel/path0/pages/resources/index.vue?macro=true";
import { default as indexB9UPYWy8HsMeta } from "/vercel/path0/pages/resources/local-requirements/[slug]/index.vue?macro=true";
import { default as indexKabIZLWjFXMeta } from "/vercel/path0/pages/resources/local-requirements/index.vue?macro=true";
import { default as indexCm6J0iO3vRMeta } from "/vercel/path0/pages/seo/all-articles/index.vue?macro=true";
import { default as indexN8lcvFAHZBMeta } from "/vercel/path0/pages/seo/index.vue?macro=true";
import { default as indexYNJVQT49cRMeta } from "/vercel/path0/pages/seo/metadata/index.vue?macro=true";
import { default as indexhnSNzfCYHtMeta } from "/vercel/path0/pages/seo/pages-structure/index.vue?macro=true";
import { default as indexb2880GkWXWMeta } from "/vercel/path0/pages/successfully-booked-a-demo/index.vue?macro=true";
import { default as index0jeiy5z9m2Meta } from "/vercel/path0/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexShMa96rOtfMeta } from "/vercel/path0/pages/trust-center/[slug]/index.vue?macro=true";
import { default as indexbwsxjOWAbjMeta } from "/vercel/path0/pages/trust-center/index.vue?macro=true";
export default [
  {
    name: "slug___en-us",
    path: "/:slug()",
    component: () => import("/vercel/path0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___de-de",
    path: "/de-de/:slug()",
    component: () => import("/vercel/path0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___sv-se",
    path: "/sv-se/:slug()",
    component: () => import("/vercel/path0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___es-es",
    path: "/es-es/:slug()",
    component: () => import("/vercel/path0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___fr-fr",
    path: "/fr-fr/:slug()",
    component: () => import("/vercel/path0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___it-it",
    path: "/it-it/:slug()",
    component: () => import("/vercel/path0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___is",
    path: "/is/:slug()",
    component: () => import("/vercel/path0/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "book-a-demo___en-us",
    path: "/book-a-demo",
    component: () => import("/vercel/path0/pages/book-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "book-a-demo___de-de",
    path: "/de-de/vorfuehrung-buchen",
    component: () => import("/vercel/path0/pages/book-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "book-a-demo___sv-se",
    path: "/sv-se/boka-en-demonstration",
    component: () => import("/vercel/path0/pages/book-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "book-a-demo___es-es",
    path: "/es-es/reserva-una-demo",
    component: () => import("/vercel/path0/pages/book-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "book-a-demo___fr-fr",
    path: "/fr-fr/reservez-une-demo",
    component: () => import("/vercel/path0/pages/book-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "book-a-demo___it-it",
    path: "/it-it/prenota-una-demo",
    component: () => import("/vercel/path0/pages/book-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "book-a-demo___is",
    path: "/is/boka-fund",
    component: () => import("/vercel/path0/pages/book-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___en-us",
    path: "/brand",
    component: () => import("/vercel/path0/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___de-de",
    path: "/de-de/marken-assets",
    component: () => import("/vercel/path0/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___sv-se",
    path: "/sv-se/varumarkestillgangar",
    component: () => import("/vercel/path0/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___es-es",
    path: "/es-es/marca",
    component: () => import("/vercel/path0/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___fr-fr",
    path: "/fr-fr/marque",
    component: () => import("/vercel/path0/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___it-it",
    path: "/it-it/marchio",
    component: () => import("/vercel/path0/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___is",
    path: "/is/vorumerki",
    component: () => import("/vercel/path0/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___en-us",
    path: "/careers/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___de-de",
    path: "/de-de/jobs/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___sv-se",
    path: "/sv-se/karriar/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___es-es",
    path: "/es-es/carreras-profesionales/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___fr-fr",
    path: "/fr-fr/offres-emploi/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___it-it",
    path: "/it-it/carriere/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___is",
    path: "/is/storf/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___en-us",
    path: "/careers",
    component: () => import("/vercel/path0/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___de-de",
    path: "/de-de/jobs",
    component: () => import("/vercel/path0/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___sv-se",
    path: "/sv-se/karriar",
    component: () => import("/vercel/path0/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___es-es",
    path: "/es-es/carreras-profesionales",
    component: () => import("/vercel/path0/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___fr-fr",
    path: "/fr-fr/offres-emploi",
    component: () => import("/vercel/path0/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___it-it",
    path: "/it-it/carriere",
    component: () => import("/vercel/path0/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___is",
    path: "/is/storf",
    component: () => import("/vercel/path0/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk-slug___en-us",
    path: "/coffee-talk/:slug()",
    component: () => import("/vercel/path0/pages/coffee-talk/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk-slug___de-de",
    path: "/de-de/coffee-talk/:slug()",
    component: () => import("/vercel/path0/pages/coffee-talk/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk-slug___sv-se",
    path: "/sv-se/coffee-talk/:slug()",
    component: () => import("/vercel/path0/pages/coffee-talk/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk-slug___es-es",
    path: "/es-es/coffee-talk/:slug()",
    component: () => import("/vercel/path0/pages/coffee-talk/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk-slug___fr-fr",
    path: "/fr-fr/coffee-talk/:slug()",
    component: () => import("/vercel/path0/pages/coffee-talk/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk-slug___it-it",
    path: "/it-it/coffee-talk/:slug()",
    component: () => import("/vercel/path0/pages/coffee-talk/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk-slug___is",
    path: "/is/coffee-talk/:slug()",
    component: () => import("/vercel/path0/pages/coffee-talk/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk___en-us",
    path: "/coffee-talk",
    component: () => import("/vercel/path0/pages/coffee-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk___de-de",
    path: "/de-de/coffee-talk",
    component: () => import("/vercel/path0/pages/coffee-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk___sv-se",
    path: "/sv-se/coffee-talk",
    component: () => import("/vercel/path0/pages/coffee-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk___es-es",
    path: "/es-es/coffee-talk",
    component: () => import("/vercel/path0/pages/coffee-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk___fr-fr",
    path: "/fr-fr/coffee-talk",
    component: () => import("/vercel/path0/pages/coffee-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk___it-it",
    path: "/it-it/coffee-talk",
    component: () => import("/vercel/path0/pages/coffee-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "coffee-talk___is",
    path: "/is/coffee-talk",
    component: () => import("/vercel/path0/pages/coffee-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "company___en-us",
    path: "/company",
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___de-de",
    path: "/de-de/unternehmen",
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___sv-se",
    path: "/sv-se/foretag",
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___es-es",
    path: "/es-es/empresa",
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___fr-fr",
    path: "/fr-fr/entreprise",
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___it-it",
    path: "/it-it/azienda",
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company___is",
    path: "/is/fyrirtaekid",
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___en-us",
    path: "/contact-us",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___de-de",
    path: "/de-de/kontakt",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___sv-se",
    path: "/sv-se/kontakta-oss",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___es-es",
    path: "/es-es/contacta-con-nosotros",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___fr-fr",
    path: "/fr-fr/contactez-nous",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___it-it",
    path: "/it-it/contattaci",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___is",
    path: "/is/hafa-samband",
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___en-us",
    path: "/cookie-policy",
    component: () => import("/vercel/path0/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___de-de",
    path: "/de-de/cookie-richtlinie",
    component: () => import("/vercel/path0/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___sv-se",
    path: "/sv-se/cookiepolicy",
    component: () => import("/vercel/path0/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___es-es",
    path: "/es-es/politica-de-cookies",
    component: () => import("/vercel/path0/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___fr-fr",
    path: "/fr-fr/politique-cookies",
    component: () => import("/vercel/path0/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___it-it",
    path: "/it-it/informativa-cookie",
    component: () => import("/vercel/path0/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___is",
    path: "/is/vafrakokustefna",
    component: () => import("/vercel/path0/pages/cookie-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___en-us",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___de-de",
    path: "/de-de/fragen-und-antworten",
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___sv-se",
    path: "/sv-se/vanliga-fragor",
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___es-es",
    path: "/es-es/preguntas-frecuentes",
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr-fr",
    path: "/fr-fr/foire-aux-questions",
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___it-it",
    path: "/it-it/domande-frequenti",
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___is",
    path: "/is/spurningar-og-svor",
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-us",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de-de",
    path: "/de-de",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sv-se",
    path: "/sv-se",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es-es",
    path: "/es-es",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr-fr",
    path: "/fr-fr",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it-it",
    path: "/it-it",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___is",
    path: "/is",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "link-in-bio___en-us",
    path: "/link-in-bio",
    component: () => import("/vercel/path0/pages/link-in-bio/index.vue").then(m => m.default || m)
  },
  {
    name: "link-in-bio___de-de",
    path: "/de-de/link-in-bio",
    component: () => import("/vercel/path0/pages/link-in-bio/index.vue").then(m => m.default || m)
  },
  {
    name: "link-in-bio___sv-se",
    path: "/sv-se/link-in-bio",
    component: () => import("/vercel/path0/pages/link-in-bio/index.vue").then(m => m.default || m)
  },
  {
    name: "link-in-bio___es-es",
    path: "/es-es/link-in-bio",
    component: () => import("/vercel/path0/pages/link-in-bio/index.vue").then(m => m.default || m)
  },
  {
    name: "link-in-bio___fr-fr",
    path: "/fr-fr/link-in-bio",
    component: () => import("/vercel/path0/pages/link-in-bio/index.vue").then(m => m.default || m)
  },
  {
    name: "link-in-bio___it-it",
    path: "/it-it/link-in-bio",
    component: () => import("/vercel/path0/pages/link-in-bio/index.vue").then(m => m.default || m)
  },
  {
    name: "link-in-bio___is",
    path: "/is/link-in-bio",
    component: () => import("/vercel/path0/pages/link-in-bio/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___en-us",
    path: "/newsletter",
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___de-de",
    path: "/de-de/email-verteiler",
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___sv-se",
    path: "/sv-se/sandlista",
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___es-es",
    path: "/es-es/boletin-informativo",
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___fr-fr",
    path: "/fr-fr/bulletin",
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___it-it",
    path: "/it-it/newsletter",
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___is",
    path: "/is/frettabref",
    component: () => import("/vercel/path0/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug___en-us",
    path: "/newsroom/:slug()",
    component: () => import("/vercel/path0/pages/newsroom/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug___de-de",
    path: "/de-de/nachrichten/:slug()",
    component: () => import("/vercel/path0/pages/newsroom/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug___sv-se",
    path: "/sv-se/nyhetsrummet/:slug()",
    component: () => import("/vercel/path0/pages/newsroom/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug___es-es",
    path: "/es-es/sala-de-prensa/:slug()",
    component: () => import("/vercel/path0/pages/newsroom/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug___fr-fr",
    path: "/fr-fr/espace-presse/:slug()",
    component: () => import("/vercel/path0/pages/newsroom/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug___it-it",
    path: "/it-it/area-stampa/:slug()",
    component: () => import("/vercel/path0/pages/newsroom/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug___is",
    path: "/is/frettir/:slug()",
    component: () => import("/vercel/path0/pages/newsroom/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___en-us",
    path: "/newsroom",
    component: () => import("/vercel/path0/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___de-de",
    path: "/de-de/nachrichten",
    component: () => import("/vercel/path0/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___sv-se",
    path: "/sv-se/nyhetsrummet",
    component: () => import("/vercel/path0/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___es-es",
    path: "/es-es/sala-de-prensa",
    component: () => import("/vercel/path0/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___fr-fr",
    path: "/fr-fr/espace-presse",
    component: () => import("/vercel/path0/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___it-it",
    path: "/it-it/area-stampa",
    component: () => import("/vercel/path0/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___is",
    path: "/is/frettir",
    component: () => import("/vercel/path0/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships___en-us",
    path: "/partnerships",
    component: () => import("/vercel/path0/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships___de-de",
    path: "/de-de/partnerschaften",
    component: () => import("/vercel/path0/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships___sv-se",
    path: "/sv-se/partnerskap",
    component: () => import("/vercel/path0/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships___es-es",
    path: "/es-es/asociaciones",
    component: () => import("/vercel/path0/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships___fr-fr",
    path: "/fr-fr/partenariats",
    component: () => import("/vercel/path0/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships___it-it",
    path: "/it-it/partenariati",
    component: () => import("/vercel/path0/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships___is",
    path: "/is/samstarf",
    component: () => import("/vercel/path0/pages/partnerships/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en-us",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___de-de",
    path: "/de-de/datenschutzrichtlinie",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___sv-se",
    path: "/sv-se/sekretesspolicy",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___es-es",
    path: "/es-es/politica-de-privacidad",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___fr-fr",
    path: "/fr-fr/politique-de-confidentialite",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___it-it",
    path: "/it-it/informativa-privacy",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___is",
    path: "/is/personuverndarskilmalar",
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "product-compensation-assistant___en-us",
    path: "/product/compensation-assistant",
    component: () => import("/vercel/path0/pages/product/compensation-assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "product-compensation-assistant___de-de",
    path: "/de-de/produkt/verguetungsassistent",
    component: () => import("/vercel/path0/pages/product/compensation-assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "product-compensation-assistant___sv-se",
    path: "/sv-se/produkten/loeneassistent",
    component: () => import("/vercel/path0/pages/product/compensation-assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "product-compensation-assistant___es-es",
    path: "/es-es/producto/asistente-de-remuneracion",
    component: () => import("/vercel/path0/pages/product/compensation-assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "product-compensation-assistant___fr-fr",
    path: "/fr-fr/produit/assistant-de-remuneration",
    component: () => import("/vercel/path0/pages/product/compensation-assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "product-compensation-assistant___it-it",
    path: "/it-it/prodotto/assistente-retribuzioni",
    component: () => import("/vercel/path0/pages/product/compensation-assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "product-compensation-assistant___is",
    path: "/is/hugbunadur/launaradgjafi",
    component: () => import("/vercel/path0/pages/product/compensation-assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "product-data-management___en-us",
    path: "/product/data-management",
    component: () => import("/vercel/path0/pages/product/data-management/index.vue").then(m => m.default || m)
  },
  {
    name: "product-data-management___de-de",
    path: "/de-de/produkt/datenmanagement",
    component: () => import("/vercel/path0/pages/product/data-management/index.vue").then(m => m.default || m)
  },
  {
    name: "product-data-management___sv-se",
    path: "/sv-se/produkten/hantering-av-data",
    component: () => import("/vercel/path0/pages/product/data-management/index.vue").then(m => m.default || m)
  },
  {
    name: "product-data-management___es-es",
    path: "/es-es/producto/gestion-de-los-datos",
    component: () => import("/vercel/path0/pages/product/data-management/index.vue").then(m => m.default || m)
  },
  {
    name: "product-data-management___fr-fr",
    path: "/fr-fr/produit/gestion-des-donnees",
    component: () => import("/vercel/path0/pages/product/data-management/index.vue").then(m => m.default || m)
  },
  {
    name: "product-data-management___it-it",
    path: "/it-it/prodotto/gestione-dei-dati",
    component: () => import("/vercel/path0/pages/product/data-management/index.vue").then(m => m.default || m)
  },
  {
    name: "product-data-management___is",
    path: "/is/hugbunadur/gagnastjornun",
    component: () => import("/vercel/path0/pages/product/data-management/index.vue").then(m => m.default || m)
  },
  {
    name: "product___en-us",
    path: "/product",
    component: () => import("/vercel/path0/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: "product___de-de",
    path: "/de-de/produkt",
    component: () => import("/vercel/path0/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: "product___sv-se",
    path: "/sv-se/produkten",
    component: () => import("/vercel/path0/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: "product___es-es",
    path: "/es-es/producto",
    component: () => import("/vercel/path0/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: "product___fr-fr",
    path: "/fr-fr/produit",
    component: () => import("/vercel/path0/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: "product___it-it",
    path: "/it-it/prodotto",
    component: () => import("/vercel/path0/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: "product___is",
    path: "/is/hugbunadur",
    component: () => import("/vercel/path0/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: "product-job-evaluation___en-us",
    path: "/product/job-evaluation",
    component: () => import("/vercel/path0/pages/product/job-evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: "product-job-evaluation___de-de",
    path: "/de-de/produkt/stellenbewertung",
    component: () => import("/vercel/path0/pages/product/job-evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: "product-job-evaluation___sv-se",
    path: "/sv-se/produkten/arbetsvaerdering",
    component: () => import("/vercel/path0/pages/product/job-evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: "product-job-evaluation___es-es",
    path: "/es-es/producto/valoracion-de-los-puestos-de-trabajo",
    component: () => import("/vercel/path0/pages/product/job-evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: "product-job-evaluation___fr-fr",
    path: "/fr-fr/produit/evaluation-des-emplois",
    component: () => import("/vercel/path0/pages/product/job-evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: "product-job-evaluation___it-it",
    path: "/it-it/prodotto/valutazione-del-lavoro",
    component: () => import("/vercel/path0/pages/product/job-evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: "product-job-evaluation___is",
    path: "/is/hugbunadur/starfsmat",
    component: () => import("/vercel/path0/pages/product/job-evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: "product-pay-equity-analysis___en-us",
    path: "/product/pay-equity-analysis",
    component: () => import("/vercel/path0/pages/product/pay-equity-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "product-pay-equity-analysis___de-de",
    path: "/de-de/produkt/analyse-der-lohngleichheit",
    component: () => import("/vercel/path0/pages/product/pay-equity-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "product-pay-equity-analysis___sv-se",
    path: "/sv-se/produkten/raettvisa-loener-analys",
    component: () => import("/vercel/path0/pages/product/pay-equity-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "product-pay-equity-analysis___es-es",
    path: "/es-es/producto/analisis-de-igualdad-salarial",
    component: () => import("/vercel/path0/pages/product/pay-equity-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "product-pay-equity-analysis___fr-fr",
    path: "/fr-fr/produit/analyse-de-l-equite-salariale",
    component: () => import("/vercel/path0/pages/product/pay-equity-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "product-pay-equity-analysis___it-it",
    path: "/it-it/prodotto/analisi-dell-equita-retributiva",
    component: () => import("/vercel/path0/pages/product/pay-equity-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "product-pay-equity-analysis___is",
    path: "/is/hugbunadur/launagreiningar",
    component: () => import("/vercel/path0/pages/product/pay-equity-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "product-remediation-actions___en-us",
    path: "/product/remediation-actions",
    component: () => import("/vercel/path0/pages/product/remediation-actions/index.vue").then(m => m.default || m)
  },
  {
    name: "product-remediation-actions___de-de",
    path: "/de-de/produkt/abhilfemassnahmen",
    component: () => import("/vercel/path0/pages/product/remediation-actions/index.vue").then(m => m.default || m)
  },
  {
    name: "product-remediation-actions___sv-se",
    path: "/sv-se/produkten/avhjaelpande-atgaerder",
    component: () => import("/vercel/path0/pages/product/remediation-actions/index.vue").then(m => m.default || m)
  },
  {
    name: "product-remediation-actions___es-es",
    path: "/es-es/producto/medidas-de-solucion",
    component: () => import("/vercel/path0/pages/product/remediation-actions/index.vue").then(m => m.default || m)
  },
  {
    name: "product-remediation-actions___fr-fr",
    path: "/fr-fr/produit/recommandations-correctives",
    component: () => import("/vercel/path0/pages/product/remediation-actions/index.vue").then(m => m.default || m)
  },
  {
    name: "product-remediation-actions___it-it",
    path: "/it-it/prodotto/azioni-di-riparazione",
    component: () => import("/vercel/path0/pages/product/remediation-actions/index.vue").then(m => m.default || m)
  },
  {
    name: "product-remediation-actions___is",
    path: "/is/hugbunadur/haekkunartilloegur",
    component: () => import("/vercel/path0/pages/product/remediation-actions/index.vue").then(m => m.default || m)
  },
  {
    name: "product-reports___en-us",
    path: "/product/reports",
    component: () => import("/vercel/path0/pages/product/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "product-reports___de-de",
    path: "/de-de/produkt/berichte",
    component: () => import("/vercel/path0/pages/product/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "product-reports___sv-se",
    path: "/sv-se/produkten/rapporter",
    component: () => import("/vercel/path0/pages/product/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "product-reports___es-es",
    path: "/es-es/producto/informes",
    component: () => import("/vercel/path0/pages/product/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "product-reports___fr-fr",
    path: "/fr-fr/produit/rapports",
    component: () => import("/vercel/path0/pages/product/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "product-reports___it-it",
    path: "/it-it/prodotto/report",
    component: () => import("/vercel/path0/pages/product/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "product-reports___is",
    path: "/is/hugbunadur/skyrslur",
    component: () => import("/vercel/path0/pages/product/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "product-software-security___en-us",
    path: "/product/software-security",
    component: () => import("/vercel/path0/pages/product/software-security/index.vue").then(m => m.default || m)
  },
  {
    name: "product-software-security___de-de",
    path: "/de-de/produkt/software-sicherheit",
    component: () => import("/vercel/path0/pages/product/software-security/index.vue").then(m => m.default || m)
  },
  {
    name: "product-software-security___sv-se",
    path: "/sv-se/produkten/programvarusaekerhet",
    component: () => import("/vercel/path0/pages/product/software-security/index.vue").then(m => m.default || m)
  },
  {
    name: "product-software-security___es-es",
    path: "/es-es/producto/seguridad-del-software",
    component: () => import("/vercel/path0/pages/product/software-security/index.vue").then(m => m.default || m)
  },
  {
    name: "product-software-security___fr-fr",
    path: "/fr-fr/produit/securite-du-logiciel",
    component: () => import("/vercel/path0/pages/product/software-security/index.vue").then(m => m.default || m)
  },
  {
    name: "product-software-security___it-it",
    path: "/it-it/prodotto/sicurezza-del-software",
    component: () => import("/vercel/path0/pages/product/software-security/index.vue").then(m => m.default || m)
  },
  {
    name: "product-software-security___is",
    path: "/is/hugbunadur/oeryggi-hugbunadarins",
    component: () => import("/vercel/path0/pages/product/software-security/index.vue").then(m => m.default || m)
  },
  {
    name: "product-user-access-controls___en-us",
    path: "/product/user-access-controls",
    component: () => import("/vercel/path0/pages/product/user-access-controls/index.vue").then(m => m.default || m)
  },
  {
    name: "product-user-access-controls___de-de",
    path: "/de-de/produkt/benutzerzugriffskontrollen",
    component: () => import("/vercel/path0/pages/product/user-access-controls/index.vue").then(m => m.default || m)
  },
  {
    name: "product-user-access-controls___sv-se",
    path: "/sv-se/produkten/styrning-av-anvaendaratkomst",
    component: () => import("/vercel/path0/pages/product/user-access-controls/index.vue").then(m => m.default || m)
  },
  {
    name: "product-user-access-controls___es-es",
    path: "/es-es/producto/control-de-acceso-de-los-usuarios",
    component: () => import("/vercel/path0/pages/product/user-access-controls/index.vue").then(m => m.default || m)
  },
  {
    name: "product-user-access-controls___fr-fr",
    path: "/fr-fr/produit/controles-dacces-des-utilisateurs",
    component: () => import("/vercel/path0/pages/product/user-access-controls/index.vue").then(m => m.default || m)
  },
  {
    name: "product-user-access-controls___it-it",
    path: "/it-it/prodotto/controlli-degli-accessi-utente",
    component: () => import("/vercel/path0/pages/product/user-access-controls/index.vue").then(m => m.default || m)
  },
  {
    name: "product-user-access-controls___is",
    path: "/is/hugbunadur/adgangsstyringar",
    component: () => import("/vercel/path0/pages/product/user-access-controls/index.vue").then(m => m.default || m)
  },
  {
    name: "product-value-based-comparison___en-us",
    path: "/product/value-based-comparison",
    component: () => import("/vercel/path0/pages/product/value-based-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "product-value-based-comparison___de-de",
    path: "/de-de/produkt/wertbasierter-vergleich",
    component: () => import("/vercel/path0/pages/product/value-based-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "product-value-based-comparison___sv-se",
    path: "/sv-se/produkten/loenekartlaeggning",
    component: () => import("/vercel/path0/pages/product/value-based-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "product-value-based-comparison___es-es",
    path: "/es-es/producto/comparacion-basada-en-el-valor",
    component: () => import("/vercel/path0/pages/product/value-based-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "product-value-based-comparison___fr-fr",
    path: "/fr-fr/produit/comparaison-selon-la-valeur",
    component: () => import("/vercel/path0/pages/product/value-based-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "product-value-based-comparison___it-it",
    path: "/it-it/prodotto/confronto-basato-sul-valore",
    component: () => import("/vercel/path0/pages/product/value-based-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "product-value-based-comparison___is",
    path: "/is/hugbunadur/virdismidadur-samanburdur",
    component: () => import("/vercel/path0/pages/product/value-based-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "product-workforce-analytics___en-us",
    path: "/product/workforce-analytics",
    component: () => import("/vercel/path0/pages/product/workforce-analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "product-workforce-analytics___de-de",
    path: "/de-de/produkt/personalanalyse",
    component: () => import("/vercel/path0/pages/product/workforce-analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "product-workforce-analytics___sv-se",
    path: "/sv-se/produkten/personalanalys",
    component: () => import("/vercel/path0/pages/product/workforce-analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "product-workforce-analytics___es-es",
    path: "/es-es/producto/analisis-del-personal",
    component: () => import("/vercel/path0/pages/product/workforce-analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "product-workforce-analytics___fr-fr",
    path: "/fr-fr/produit/analyse-du-personnel",
    component: () => import("/vercel/path0/pages/product/workforce-analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "product-workforce-analytics___it-it",
    path: "/it-it/prodotto/analisi-del-personale",
    component: () => import("/vercel/path0/pages/product/workforce-analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "product-workforce-analytics___is",
    path: "/is/hugbunadur/jafnrettisvisar",
    component: () => import("/vercel/path0/pages/product/workforce-analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles-slug___en-us",
    path: "/resources/articles/:slug()",
    component: () => import("/vercel/path0/pages/resources/articles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles-slug___de-de",
    path: "/de-de/ressourcen/artikel/:slug()",
    component: () => import("/vercel/path0/pages/resources/articles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles-slug___sv-se",
    path: "/sv-se/resurser/artiklar/:slug()",
    component: () => import("/vercel/path0/pages/resources/articles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles-slug___es-es",
    path: "/es-es/recursos/articulos/:slug()",
    component: () => import("/vercel/path0/pages/resources/articles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles-slug___fr-fr",
    path: "/fr-fr/ressources/articles/:slug()",
    component: () => import("/vercel/path0/pages/resources/articles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles-slug___it-it",
    path: "/it-it/risorse/articoli/:slug()",
    component: () => import("/vercel/path0/pages/resources/articles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles-slug___is",
    path: "/is/fraedsluefni/greinar/:slug()",
    component: () => import("/vercel/path0/pages/resources/articles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles___en-us",
    path: "/resources/articles",
    component: () => import("/vercel/path0/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles___de-de",
    path: "/de-de/ressourcen/artikel",
    component: () => import("/vercel/path0/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles___sv-se",
    path: "/sv-se/resurser/artiklar",
    component: () => import("/vercel/path0/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles___es-es",
    path: "/es-es/recursos/articulos",
    component: () => import("/vercel/path0/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles___fr-fr",
    path: "/fr-fr/ressources/articles",
    component: () => import("/vercel/path0/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles___it-it",
    path: "/it-it/risorse/articoli",
    component: () => import("/vercel/path0/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-articles___is",
    path: "/is/fraedsluefni/greinar",
    component: () => import("/vercel/path0/pages/resources/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success-slug___en-us",
    path: "/resources/customer-success/:slug()",
    component: () => import("/vercel/path0/pages/resources/customer-success/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success-slug___de-de",
    path: "/de-de/ressourcen/kundenerfolg/:slug()",
    component: () => import("/vercel/path0/pages/resources/customer-success/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success-slug___sv-se",
    path: "/sv-se/resurser/kundframgang/:slug()",
    component: () => import("/vercel/path0/pages/resources/customer-success/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success-slug___es-es",
    path: "/es-es/recursos/exito-del-cliente/:slug()",
    component: () => import("/vercel/path0/pages/resources/customer-success/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success-slug___fr-fr",
    path: "/fr-fr/ressources/succes-client/:slug()",
    component: () => import("/vercel/path0/pages/resources/customer-success/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success-slug___it-it",
    path: "/it-it/risorse/successo-clienti/:slug()",
    component: () => import("/vercel/path0/pages/resources/customer-success/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success-slug___is",
    path: "/is/fraedsluefni/arangur-vidskiptavina/:slug()",
    component: () => import("/vercel/path0/pages/resources/customer-success/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success___en-us",
    path: "/resources/customer-success",
    component: () => import("/vercel/path0/pages/resources/customer-success/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success___de-de",
    path: "/de-de/ressourcen/kundenerfolg",
    component: () => import("/vercel/path0/pages/resources/customer-success/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success___sv-se",
    path: "/sv-se/resurser/kundframgang",
    component: () => import("/vercel/path0/pages/resources/customer-success/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success___es-es",
    path: "/es-es/recursos/exito-del-cliente",
    component: () => import("/vercel/path0/pages/resources/customer-success/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success___fr-fr",
    path: "/fr-fr/ressources/succes-client",
    component: () => import("/vercel/path0/pages/resources/customer-success/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success___it-it",
    path: "/it-it/risorse/successo-clienti",
    component: () => import("/vercel/path0/pages/resources/customer-success/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-customer-success___is",
    path: "/is/fraedsluefni/arangur-vidskiptavina",
    component: () => import("/vercel/path0/pages/resources/customer-success/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___en-us",
    path: "/resources/guides/:slug()",
    component: () => import("/vercel/path0/pages/resources/guides/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___de-de",
    path: "/de-de/ressourcen/guides/:slug()",
    component: () => import("/vercel/path0/pages/resources/guides/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___sv-se",
    path: "/sv-se/resurser/guider/:slug()",
    component: () => import("/vercel/path0/pages/resources/guides/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___es-es",
    path: "/es-es/recursos/guias/:slug()",
    component: () => import("/vercel/path0/pages/resources/guides/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___fr-fr",
    path: "/fr-fr/ressources/guides/:slug()",
    component: () => import("/vercel/path0/pages/resources/guides/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___it-it",
    path: "/it-it/risorse/guide/:slug()",
    component: () => import("/vercel/path0/pages/resources/guides/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides-slug___is",
    path: "/is/fraedsluefni/leidarvisar/:slug()",
    component: () => import("/vercel/path0/pages/resources/guides/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides___en-us",
    path: "/resources/guides",
    component: () => import("/vercel/path0/pages/resources/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides___de-de",
    path: "/de-de/ressourcen/guides",
    component: () => import("/vercel/path0/pages/resources/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides___sv-se",
    path: "/sv-se/resurser/guider",
    component: () => import("/vercel/path0/pages/resources/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides___es-es",
    path: "/es-es/recursos/guias",
    component: () => import("/vercel/path0/pages/resources/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides___fr-fr",
    path: "/fr-fr/ressources/guides",
    component: () => import("/vercel/path0/pages/resources/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides___it-it",
    path: "/it-it/risorse/guide",
    component: () => import("/vercel/path0/pages/resources/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-guides___is",
    path: "/is/fraedsluefni/leidarvisar",
    component: () => import("/vercel/path0/pages/resources/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___en-us",
    path: "/resources",
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___de-de",
    path: "/de-de/ressourcen",
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___sv-se",
    path: "/sv-se/resurser",
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___es-es",
    path: "/es-es/recursos",
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___fr-fr",
    path: "/fr-fr/ressources",
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___it-it",
    path: "/it-it/risorse",
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources___is",
    path: "/is/fraedsluefni",
    component: () => import("/vercel/path0/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements-slug___en-us",
    path: "/resources/local-requirements/:slug()",
    component: () => import("/vercel/path0/pages/resources/local-requirements/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements-slug___de-de",
    path: "/de-de/ressourcen/lokale-anforderungen/:slug()",
    component: () => import("/vercel/path0/pages/resources/local-requirements/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements-slug___sv-se",
    path: "/sv-se/resurser/lokala-krav/:slug()",
    component: () => import("/vercel/path0/pages/resources/local-requirements/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements-slug___es-es",
    path: "/es-es/recursos/requisitos-locales/:slug()",
    component: () => import("/vercel/path0/pages/resources/local-requirements/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements-slug___fr-fr",
    path: "/fr-fr/ressources/exigences-locales/:slug()",
    component: () => import("/vercel/path0/pages/resources/local-requirements/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements-slug___it-it",
    path: "/it-it/risorse/requisiti-locali/:slug()",
    component: () => import("/vercel/path0/pages/resources/local-requirements/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements-slug___is",
    path: "/is/fraedsluefni/stadbundnar-krofur/:slug()",
    component: () => import("/vercel/path0/pages/resources/local-requirements/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements___en-us",
    path: "/resources/local-requirements",
    component: () => import("/vercel/path0/pages/resources/local-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements___de-de",
    path: "/de-de/ressourcen/lokale-anforderungen",
    component: () => import("/vercel/path0/pages/resources/local-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements___sv-se",
    path: "/sv-se/resurser/lokala-krav",
    component: () => import("/vercel/path0/pages/resources/local-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements___es-es",
    path: "/es-es/recursos/requisitos-locales",
    component: () => import("/vercel/path0/pages/resources/local-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements___fr-fr",
    path: "/fr-fr/ressources/exigences-locales",
    component: () => import("/vercel/path0/pages/resources/local-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements___it-it",
    path: "/it-it/risorse/requisiti-locali",
    component: () => import("/vercel/path0/pages/resources/local-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "resources-local-requirements___is",
    path: "/is/fraedsluefni/stadbundnar-krofur",
    component: () => import("/vercel/path0/pages/resources/local-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-all-articles___en-us",
    path: "/seo/all-articles",
    component: () => import("/vercel/path0/pages/seo/all-articles/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-all-articles___de-de",
    path: "/de-de/seo/all-articles",
    component: () => import("/vercel/path0/pages/seo/all-articles/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-all-articles___sv-se",
    path: "/sv-se/seo/all-articles",
    component: () => import("/vercel/path0/pages/seo/all-articles/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-all-articles___es-es",
    path: "/es-es/seo/all-articles",
    component: () => import("/vercel/path0/pages/seo/all-articles/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-all-articles___fr-fr",
    path: "/fr-fr/seo/all-articles",
    component: () => import("/vercel/path0/pages/seo/all-articles/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-all-articles___it-it",
    path: "/it-it/seo/all-articles",
    component: () => import("/vercel/path0/pages/seo/all-articles/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-all-articles___is",
    path: "/is/seo/all-articles",
    component: () => import("/vercel/path0/pages/seo/all-articles/index.vue").then(m => m.default || m)
  },
  {
    name: "seo___en-us",
    path: "/seo",
    component: () => import("/vercel/path0/pages/seo/index.vue").then(m => m.default || m)
  },
  {
    name: "seo___de-de",
    path: "/de-de/seo",
    component: () => import("/vercel/path0/pages/seo/index.vue").then(m => m.default || m)
  },
  {
    name: "seo___sv-se",
    path: "/sv-se/seo",
    component: () => import("/vercel/path0/pages/seo/index.vue").then(m => m.default || m)
  },
  {
    name: "seo___es-es",
    path: "/es-es/seo",
    component: () => import("/vercel/path0/pages/seo/index.vue").then(m => m.default || m)
  },
  {
    name: "seo___fr-fr",
    path: "/fr-fr/seo",
    component: () => import("/vercel/path0/pages/seo/index.vue").then(m => m.default || m)
  },
  {
    name: "seo___it-it",
    path: "/it-it/seo",
    component: () => import("/vercel/path0/pages/seo/index.vue").then(m => m.default || m)
  },
  {
    name: "seo___is",
    path: "/is/seo",
    component: () => import("/vercel/path0/pages/seo/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-metadata___en-us",
    path: "/seo/metadata",
    component: () => import("/vercel/path0/pages/seo/metadata/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-metadata___de-de",
    path: "/de-de/seo/metadata",
    component: () => import("/vercel/path0/pages/seo/metadata/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-metadata___sv-se",
    path: "/sv-se/seo/metadata",
    component: () => import("/vercel/path0/pages/seo/metadata/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-metadata___es-es",
    path: "/es-es/seo/metadata",
    component: () => import("/vercel/path0/pages/seo/metadata/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-metadata___fr-fr",
    path: "/fr-fr/seo/metadata",
    component: () => import("/vercel/path0/pages/seo/metadata/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-metadata___it-it",
    path: "/it-it/seo/metadata",
    component: () => import("/vercel/path0/pages/seo/metadata/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-metadata___is",
    path: "/is/seo/metadata",
    component: () => import("/vercel/path0/pages/seo/metadata/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-pages-structure___en-us",
    path: "/seo/pages-structure",
    component: () => import("/vercel/path0/pages/seo/pages-structure/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-pages-structure___de-de",
    path: "/de-de/seo/pages-structure",
    component: () => import("/vercel/path0/pages/seo/pages-structure/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-pages-structure___sv-se",
    path: "/sv-se/seo/pages-structure",
    component: () => import("/vercel/path0/pages/seo/pages-structure/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-pages-structure___es-es",
    path: "/es-es/seo/pages-structure",
    component: () => import("/vercel/path0/pages/seo/pages-structure/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-pages-structure___fr-fr",
    path: "/fr-fr/seo/pages-structure",
    component: () => import("/vercel/path0/pages/seo/pages-structure/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-pages-structure___it-it",
    path: "/it-it/seo/pages-structure",
    component: () => import("/vercel/path0/pages/seo/pages-structure/index.vue").then(m => m.default || m)
  },
  {
    name: "seo-pages-structure___is",
    path: "/is/seo/pages-structure",
    component: () => import("/vercel/path0/pages/seo/pages-structure/index.vue").then(m => m.default || m)
  },
  {
    name: "successfully-booked-a-demo___en-us",
    path: "/successfully-booked-a-demo",
    component: () => import("/vercel/path0/pages/successfully-booked-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "successfully-booked-a-demo___de-de",
    path: "/de-de/successfully-booked-a-demo",
    component: () => import("/vercel/path0/pages/successfully-booked-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "successfully-booked-a-demo___sv-se",
    path: "/sv-se/successfully-booked-a-demo",
    component: () => import("/vercel/path0/pages/successfully-booked-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "successfully-booked-a-demo___es-es",
    path: "/es-es/successfully-booked-a-demo",
    component: () => import("/vercel/path0/pages/successfully-booked-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "successfully-booked-a-demo___fr-fr",
    path: "/fr-fr/successfully-booked-a-demo",
    component: () => import("/vercel/path0/pages/successfully-booked-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "successfully-booked-a-demo___it-it",
    path: "/it-it/successfully-booked-a-demo",
    component: () => import("/vercel/path0/pages/successfully-booked-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "successfully-booked-a-demo___is",
    path: "/is/successfully-booked-a-demo",
    component: () => import("/vercel/path0/pages/successfully-booked-a-demo/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en-us",
    path: "/terms-and-conditions",
    component: () => import("/vercel/path0/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___de-de",
    path: "/de-de/agb",
    component: () => import("/vercel/path0/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___sv-se",
    path: "/sv-se/villkor",
    component: () => import("/vercel/path0/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___es-es",
    path: "/es-es/terminos-y-condiciones",
    component: () => import("/vercel/path0/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___fr-fr",
    path: "/fr-fr/termes-et-conditions",
    component: () => import("/vercel/path0/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___it-it",
    path: "/it-it/termini-e-condizioni",
    component: () => import("/vercel/path0/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___is",
    path: "/is/skilmalar",
    component: () => import("/vercel/path0/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center-slug___en-us",
    path: "/trust-center/:slug()",
    component: () => import("/vercel/path0/pages/trust-center/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center-slug___de-de",
    path: "/de-de/trust-center/:slug()",
    component: () => import("/vercel/path0/pages/trust-center/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center-slug___sv-se",
    path: "/sv-se/trust-center/:slug()",
    component: () => import("/vercel/path0/pages/trust-center/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center-slug___es-es",
    path: "/es-es/trust-center/:slug()",
    component: () => import("/vercel/path0/pages/trust-center/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center-slug___fr-fr",
    path: "/fr-fr/trust-center/:slug()",
    component: () => import("/vercel/path0/pages/trust-center/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center-slug___it-it",
    path: "/it-it/trust-center/:slug()",
    component: () => import("/vercel/path0/pages/trust-center/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center-slug___is",
    path: "/is/trust-center/:slug()",
    component: () => import("/vercel/path0/pages/trust-center/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center___en-us",
    path: "/trust-center",
    component: () => import("/vercel/path0/pages/trust-center/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center___de-de",
    path: "/de-de/trust-center",
    component: () => import("/vercel/path0/pages/trust-center/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center___sv-se",
    path: "/sv-se/trust-center",
    component: () => import("/vercel/path0/pages/trust-center/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center___es-es",
    path: "/es-es/trust-center",
    component: () => import("/vercel/path0/pages/trust-center/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center___fr-fr",
    path: "/fr-fr/trust-center",
    component: () => import("/vercel/path0/pages/trust-center/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center___it-it",
    path: "/it-it/trust-center",
    component: () => import("/vercel/path0/pages/trust-center/index.vue").then(m => m.default || m)
  },
  {
    name: "trust-center___is",
    path: "/is/trust-center",
    component: () => import("/vercel/path0/pages/trust-center/index.vue").then(m => m.default || m)
  }
]