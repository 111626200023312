import * as prismicH from '@prismicio/helpers'
// Documentation: https://prismic.io/docs/technical-reference/prismicio-richtext

const replaceAsteriskPatternWithSpan = (input: string) => {
  // We want to replace the following pattern with html.
  // This: **my-class-name**my text string**
  // Becomes: <span class="my-class-name">my text string</span>
  const pattern: RegExp = /\*\*(.*?)\*\*(.*?)\*\*/g

  return input.replace(pattern, (_match: string, className: string, text: string): string => {
    className = className.trim()
    return `<span class="${className}">${text}</span>`
  })
}

const addInlineColorStyles = (input: string) => {
  return input
    .replace('#122233', '<span class="inline-color-code inline-color-code--primary">#122233</span>')
    .replace('#fbd33b', '<span class="inline-color-code inline-color-code--yellow">#fbd33b</span>')
    .replace('#27c0b9', '<span class="inline-color-code inline-color-code--green">#27c0b9</span>')
    .replace('#ff8d69', '<span class="inline-color-code inline-color-code--red">#ff8d69</span>')
    .replace('#58d3f4', '<span class="inline-color-code inline-color-code--blue">#58d3f4</span>')
    .replace('#e1a3ff', '<span class="inline-color-code inline-color-code--purple">#e1a3ff</span>')
}

export const htmlSerializer: prismicH.HTMLMapSerializer = {
  /**
  heading2: ({ children }) => `<h2 class="foobar">${children}</h2>`,
     */
  paragraph: ({ children }) => {
    // { children, node, key, text }
    let textBody = children
    textBody = addInlineColorStyles(textBody)
    textBody = replaceAsteriskPatternWithSpan(textBody)

    return `<p class="editor-body-text">${textBody}</p>`
  },
  hyperlink: ({ children, node }) => {
    // { children, node, key, text }
    const url = node.data.url
    const linkText = children

    return getProcessedAnchorTagForInternalRouting(url, linkText)
  },
  label: ({ node, children }) => {
    if (node.data.label === 'is-superscript') {
      return `<sup class="${node.data.label}">${children}</sup>`
    } else if (node.data.label === 'is-subscript') {
      return `<sub class="${node.data.label}">${children}</sub>`
    } else {
      return `<span class="${node.data.label}">${children}</span>`
    }
  },
}
