<script setup lang="ts">
const props = defineProps({
  headingLevel: {
    type: String as PropType<UiHeadingOption>,
    required: true,
    validator(value: UiHeadingOption) {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
    },
  },
  headingClass: {
    type: String as PropType<UiHeadingOptionClass | undefined>,
    required: false,
    validator(value: UiHeadingOptionClass) {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'h9'].includes(value)
    },
    default: undefined,
  },
  removeDefaultBottomMargin: {
    type: Boolean,
    default: false,
  },
  textAlignment: {
    type: String as PropType<'left' | 'center' | 'right'>,
    default: 'left',
  },
})

const dynamicClasses = computed(() => {
  return [
    `ui-heading--${props.headingClass ?? props.headingLevel}`,
    props.removeDefaultBottomMargin ? '' : 'ui-heading--has-bm',
    `ui-heading--align-text-${props.textAlignment}`,
  ]
})
</script>

<template>
  <component :is="props.headingLevel" class="ui-heading" :class="dynamicClasses">
    <slot />
  </component>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
h1.ui-heading,
h2.ui-heading,
h3.ui-heading,
h4.ui-heading,
h5.ui-heading,
h6.ui-heading {
  line-height: var(--line-height-headings);
  white-space: pre-line;

  &--has-bm {
    .fluid(margin-bottom, 16, 24);
  }
  &--align-text {
    &-center {
      text-align: center;
    }
    &-right {
      text-align: center;
    }
  }
}

.ui-heading--h1 {
  .h1-style();
}
.ui-heading--h2 {
  .h2-style();
}
.ui-heading--h3 {
  .h3-style();
}
.ui-heading--h4 {
  .h4-style();
}
.ui-heading--h5 {
  .h5-style();
}
.ui-heading--h6 {
  .h6-style();
}
/**
  * Since we have design where headings are displayed smaller than h6 defaults,
  * we allow settings for props.headingClass below that.
*/
.ui-heading--h7 {
  .h7-style();
}
.ui-heading--h8 {
  .h8-style();
}
.ui-heading--h9 {
  .h9-style();
}
</style>
