/**
 * Processes a given URL to normalize it for internal use or returns it unaltered if external.
 *
 * This function identifies if a URL is internal based on specific criteria (e.g., starting with
 * 'https:///' or containing a base domain). For internal URLs, it standardizes the format by
 * removing unnecessary prefixes and ensuring a proper slug structure with a single leading slash
 * and no trailing slashes. External URLs are returned as-is, without modification.
 *
 * @param {string} url - The URL to process. This can be an internal or external URL.
 * @returns {string} The normalized internal URL or the original external URL.
 */

import consts from '@/consts'

// In some cases we might get links from Prismic that look like this: 'https:///my-internal-url'.
const brokenInternalLinkPrefix = 'https:///'

export default (url: string) => {
  const isExternalUrl = !url.startsWith(brokenInternalLinkPrefix) && !url.includes(consts.baseUrl)
  if (isExternalUrl) {
    return url
  }

  let internalSlug = ''

  if (url.startsWith(brokenInternalLinkPrefix)) {
    internalSlug = url.split(brokenInternalLinkPrefix)[1]
  } else if (url.includes(consts.baseDomain)) {
    internalSlug = url.split(consts.baseDomain)[1]
  }

  // Ensure there is only one leading slash and no trailing slash.
  internalSlug = '/' + internalSlug.replace(/^\/+|\/+$/g, '')

  internalSlug = getUpdatedLinkAccordingToRedirectList(internalSlug)

  return internalSlug
}
