/**
 * @param {string} url - A URL.
 * @param {string} linkText - The text for the anchor tag.
 * @returns {string} Returns an HTML anchor tag.
 */

export default (url: string | undefined, linkText: string) => {
  if (url === undefined) {
    // This should not happen, so essentially do nothing.
    return `<a href="${url}" rel="noopener noreferrer" target="_blank">${linkText}</a>`
  } else {
    const finalUrl = getProcessedUrlForInternalRouting(url)
    const isExternalUrl = finalUrl.includes('http')

    if (isExternalUrl) {
      return `<a href="${finalUrl}" rel="noopener noreferrer" target="_blank">${linkText}</a>`
    } else {
      return `<a href="${finalUrl}">${linkText}</a>`
    }
  }
}
